.page-shopkeeper {
    .page-header {
        border: none;
        margin: 0;
    }

    .page-content {
        @include linearGradient(
            $color1: #fafafa,
            $color2: #ffffff,
            $angle: to bottom
        );
        padding: 25px 0 0;
        position: relative;

        &::after {
            @include imageBg(
                $image: "pattern-shopkeeper.jpg",
                $pos: center 0
            );
            content: "";
            left: 0;
            position: absolute;
            top: 0;
            @include size(100%, 25px)
        }

        .box-advantage {
            @include imageBg(
                $image: "bg-advantage.jpg",
                $repeat: repeat,
                $pos: 0 0
            );
            border: solid 1px $border-color;
            padding: 50px 100px 120px;

            > h2 {
                @include imageBg(
                    $image: "favicon.png",
                    $pos: center 0
                );
                color: $brand-color;
                font-size: 36px;
                text-transform: uppercase;
                font-weight: weight(medium);
                margin: 0 0 30px;
                padding: 40px 0 0;
                position: relative;
                text-align: center;

                > span {
                    font-weight: weight(extralight);
                }
            }
        }

        .box-stock {
            padding: 100px 0 0;
            position: relative;
            text-align: center;

            &::before {
                background-color: #ffffff;
                @include imageBg(
                    $image: "icon-check.png"
                );
                border-radius: 50%;
                box-shadow: 0 5px 15px rgba(#000000, 0.03);
                content: "";
                position: absolute;
                top: -20px;
                left: calc(50% - 45px);
                @include size(90px);
                z-index: 1;
            }

            > h3 {
                color: $brand-color;
                font-size: 36px;

                > span {
                    font-weight: weight(medium);
                }
            }

            > h4 {
                color: $text-color-dark;
                font-size: 17px;
                font-weight: weight(light);
            }
        }

        @include breakpoint-min(medium) {
            .box-advantage {
                > h2 {
                    > span {
                        display: block;
                    }
                }
            }
        }

        @include breakpoint-max(medium) {
            .box-advantage {
                padding: 20px 20px 45px;

                > h2 {
                    font-size: 24px;
                }
            }
        }

        @include breakpoint-max(small) {
            .box-stock {
                > h3 {
                    > span {
                        display: block;
                    }
                }

                > h4 {
                    margin: 20px 0 0;
                }
            }
        }
    }

    .box-items {
        @include imageBg(
            $image: "bg-shopkeeper-items.jpg",
            $size: cover
        );
        margin: 60px 0 0;
        padding: 60px 0;

        .item {
            @include flex(
                $direction: column,
                $align: center
            );
            text-align: center;

            > img {
                margin: 0 0 20px;
            }

            > h3 {
                color: #ffffff;
                font-size: 30px;
                font-weight: weight(extralight);
                max-width: 290px;
            }
        }

        @include breakpoint-max(large) {
            .item {
                width: 100%;

                & + .item {
                    margin: 50px 0 0;
                }
            }
        }
    }

    .box-forms {
        @include imageBg(
            $image: "bg-forms.jpg",
            $size: cover
        );
        padding: 60px 0 80px;
        
        .box-title {
            margin: 0 0 30px;

            >.uppertitle {
                color: $brand-color;
                font-size: 20px;
                font-weight: weight(extralight);
                line-height: 1.2em;
            }
            >.title {
                color: $text-color-dark;
                font-size: 36px;
            }
        }

        .box-form-contact {
            .box-title {
                padding: 0 0 0 85px;
                position: relative;

                &::before {
                    @include imageBg(
                        $image: "icon-form.png"
                    );
                    content: "";
                    left: 0;
                    position: absolute;
                    top: 4px;
                    @include size(55px);
                }
            }

            .box-form {
                padding: 0 50px 0 0;

                > form {
                    padding: 0 0 0 85px;
                }

                .field-group {
                    & + .field-group {
                        margin: 30px 0 0;
                    }

                    .field {
                        border-radius: 3px;

                        @include placeholder {
                            color: $brand-color;
                            opacity: 1;
                        }
                    }
    
                    &.buttons {
                        @include flex(
                            $justify: flex-end
                        );
                        margin: 30px 0 0;
        
                        .button {
                            background-color: transparent;
                            border: solid 1px $brand-color;
                            border-radius: 3px;
                            color: $brand-color;
                            cursor: pointer;
                            @include flex(
                                $justify: center,
                                $align: center
                            );
                            font-size: 15px;
                            font-weight: weight(regular);
                            line-height: 1.25em;
                            overflow: hidden;
                            padding: 16px 35px;
                            position: relative;
                            text-transform: uppercase;
                            @include transition;
                            z-index: 1;
    
                            > i {
                                display: block;
                                font-size: 12px;
                                margin: 0 0 0 20px;
                            }
        
                            &:hover {
                                background-color: $brand-color;
                                color: #ffffff;
                            }
                        }
                    }
                }
            }
        }

        .box-form-orcamento {
            .box-form {
                background-color: #000000;
                @include imageBg(
                    $image: "bg-form-orcamento-lojista.jpg",
                    $size: cover
                );
                border-radius: 3px;
                padding: 60px 75px;

                .box-title {
                    text-align: center;

                    >.title {
                        color: #ffffff;
                    }
                }

                .field-group {
                    & + .field-group {
                        margin: 30px 0 0;
                    }

                    .field {
                        background-color: rgba(#4c4c4c, 0.8);
                        border: none;
                        border-radius: 3px;
                        color: #ffffff;
                        padding: 12px 15px;

                        @include placeholder {
                            color: #ffffff;
                            opacity: 1;
                        }

                        &.custom-select {
                            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKBAMAAABGe2PVAAAAKlBMVEUAAAD///////////////////////////////////////////////////+Gu8ovAAAADnRSTlMATEM7GgRHMhMNCCwjIlJVxZcAAABNSURBVAjXY2ApYAADdgeGMOEAEIvVMJWBU9EKxFwsNIGBYRGQAApogaUyGBjaIMrYBR1YBKGaN8pelGaAAB5BwQMMUOAowgADHA0gEgCsmAs69n4WtAAAAABJRU5ErkJggg==");
                            background-position: right 15px center;
                            background-repeat: no-repeat;
                            padding-right: 50px;

                            option {
                                background-color: #ffffff;
                                color: $text-color-light;
                            }
                        }

                        &.error {
                            background-color: lighten($error, 40%);
                            border-color: lighten($error, 40%);
                            color: $error;

                            @include placeholder {
                                color: $error;
                            }
                        }
                    }

                    textarea {
                        height: 70px;
                    }

                    > i {
                        color: #fff;
                        display: block;
                        font-size: 13px;
                        line-height: 1.2em;
                        margin: 5px 0 0;
                    }

                    >span.error {
                        bottom: 100%;
                        color: lighten($error, 40%);
                        margin: 0 0 3px;
                        top: auto;
                    }
    
                &.buttons {
                    @include flex(
                        $justify: center
                    );
    
                    .button {
                        background-color: #ffffff;
                        border-radius: 3px;
                        color: #ffffff;
                        cursor: pointer;
                        @include flex(
                            $justify: center,
                            $align: center
                        );
                        font-size: 15px;
                        font-weight: weight(regular);
                        line-height: 1.25em;
                        overflow: hidden;
                        padding: 16px 20px;
                        position: relative;
                        text-transform: uppercase;
                        @include transition;
                        z-index: 1;

                        &::before {
                            @include linearGradient(
                                $angle: to top
                            );
                            content: "";
                            left: 0;
                            opacity: 1;
                            position: absolute;
                            top: 0;
                            @include transition;
                            @include size(100%);
                            z-index: -1;
                        }

                        > i {
                            display: block;
                            font-size: 12px;
                            margin: 0 0 0 20px;
                        }
    
                        &:hover {
                            color: $brand-color;

                            &::before {
                                opacity: 0;
                            }
                        }
                    }
                }
                }
            }
        }

        @include breakpoint-min(large) {
            .container {
                position: relative;
    
                &::before {
                    background-color: #ffffff;
                    content: "";
                    left: 0;
                    position: absolute;
                    top: -60px;
                    @include size(485px, 20px);
                }
            }
        }

        @include breakpoint-max(large) {
            .box-title {
                >.title {
                    font-size: 22px;
                }
            }

            .box-form-contact {
                .box-form {
                    padding: 0;

                    > form {
                        padding: 0;
                    }
                }
            }

            .box-form-orcamento {
                .box-form {
                    padding: 20px;
                }
            }
        }

        @include breakpoint-max(medium) {
            .box-form-contact {
                margin: 0 auto;
                max-width: 350px;
                width: 100%;

                .box-form {
                    .field-group {
                        &.buttons {
                            justify-content: center;
                        }
                    }
                }
            }

            .box-form-orcamento {
                margin: 60px auto 0;
                max-width: 350px;
                width: 100%;
            }
        }
    }

    .box-partners {
        background-color: #f7f7f7;
        padding: 60px 0;
        text-align: center;

        .box-title {
            margin: 0 0 30px;

            >.upper-title {
                color: $brand-color;
                font-size: 20px;
                font-weight: weight(extralight);
            }

            >.title {
                color: $brand-color;
                font-size: 60px;

                > span {
                    color: $text-color-dark;
                }
            }
        }

        .list-items {
            position: relative;

            .swiper-slide {
                > span,
                > a {
                    border-radius: 3px;
                    display: block;
                    margin: 0 auto;
                    max-width: 270px;
                    overflow: hidden;
                    position: relative;

                    &::before {
                        border: solid 1px $border-color;
                        border-radius: 3px;
                        content: "";
                        left: 0;
                        position: absolute;
                        top: 0;
                        @include size(100%);
                        z-index: 1;
                    }
                }

                > a {
                    &::after {
                        background-color: rgba(#000000, 0.75);
                        border-radius: 4px;
                        content: "\f35d";
                        color: #ffffff;
                        @include flex(
                            $align: center,
                            $justify: center
                        );
                        font-family: 'Font Awesome 5 Free';
                        font-size: 20px;
                        font-weight: 900;
                        left: 0;
                        opacity: 0;
                        position: absolute;
                        top: 0;
                        @include transition;
                        @include size(100%);
                    }

                    &:hover {
                        &::after {
                            opacity: 1;
                        }
                    }
                }
            }

            > span {
                @include imageBg;
                border-radius: 3px;
                display: block;
                position: absolute;
                text-align: left;
                text-indent: -9999px;
                top: calc(50% - 20px);
                @include transition;
                @include size(40px);

                &.button-prev {
                    background-image: url(../images/arrow-carousel-left.png);
                    left: -45px;
                }

                &.button-next {
                    background-image: url(../images/arrow-carousel-right.png);
                    right: -45px;
                }

                &:hover {
                    background-color: #ffffff;
                    box-shadow: 0 3px 15px rgba(#000000, 0.03);
                }
            }
        }

        @include breakpoint-max(1279px) {
            .list-items {
                margin: 0 auto;
                max-width: 870px;
            }
        }

        @include breakpoint-max(large) {
            .box-title {
                >.title {
                    font-size: 36px;
                }
            }

            .list-items {
                max-width: 600px;
            }
        }

        @include breakpoint-max(medium) {
            .list-items {
                max-width: 270px;
            }
        }

        @include breakpoint-max(small) {
            .list-items {
                max-width: 200px;
            }
        }
    }
}