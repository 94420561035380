.box-top-suppliers {
    background-color: #fafafa;
    padding: 70px 0 90px;

    .box-title {
        margin: 0 0 30px;

        >.title {
            color: $brand-color;
            font-size: 60px;

            > span {
                color: $text-color-dark;
            }
        }

        >.subtitle {
            color: $brand-color;
            font-size: 20px;
            font-weight: weight(extralight);
            line-height: 1.25em;
            margin: 5px 0 0;
        }
    }

    .item {
        >.inner {
            @include imageBg(
                $image: "bg-fornecedores.jpg",
                $size: cover
            );
            box-shadow: 0 10px 20px rgba(#000000, 0.05);
            height: 100%;
            padding: 35px 50px;

            > figure {
                margin: 0 0 20px;
                text-align: center;
            }

            .item-text {
                color: $text-color-light;

                p {
                    font-size: 16px;
                    font-weight: weight(light);
                    line-height: em(25, 16);

                    & + p {
                        margin: 12px 0 0;
                    }
                }
            }

            .item-alert {
                >.inner {
                    border: solid 1px #f09500;
                    border-radius: 3px;
                    padding: 20px 30px;

                    p {
                        color: #f09500;
                        font-size: 14px;
                        font-weight: weight(medium);
                        line-height: 1.35em;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    @include breakpoint-max(large) {
        padding: 60px 0 90px;

        .box-title {
            text-align: center;

            >.title {
                font-size: 48px;

                > span {
                    display: block;
                }
            }

            >.subtitle {
                font-size: 18px;
            }
        }

        .item {
            width: 100%;
            
            & + .item {
                margin: 30px 0 0;
            }

            >.inner {
                margin: 0 auto;
                max-width: 570px;
                padding: 35px 25px;
                width: 100%;
            }
        }
    }

    @include breakpoint-max(small) {
        .item {
            >.inner {
                .item-text {
                    width: 100%;
                }

                .item-alert {
                    margin: 10px 0 0;
                    width: 100%;

                    >.inner {
                        padding: 20px;
                    }
                }
            }
        }
    }
}