.page-about {
    .page-header {
        border: none;
        margin: 0;
        
        @include breakpoint-min(xlarge) {
            padding-bottom: 100px;
        }
    }

    .page-content {
        @include linearGradient(
            $color1: #fafafa,
            $color2: #ffffff,
            $angle: to bottom
        );
        padding: 90px 0 0;
        position: relative;

        .container {
            >.row {
                align-items: flex-start;
            }
        }

        &::after {
            @include imageBg(
                $image: "pattern-shopkeeper.jpg",
                $pos: center 0
            );
            content: "";
            left: 0;
            position: absolute;
            top: 0;
            @include size(100%, 25px)
        }

        .box-text {
            @include imageBg(
                $image: "favicon.png",
                $pos: 15px 0
            );
            padding-top: 40px;

            h2 {
                color: $brand-color;
                font-size: 36px;
                font-weight: weight(extralight);
                margin: 20px 0;

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        .box-image {
            @include flex(
                $direction: column,
                $align: center
            );
            position: relative;
            z-index: 2;

            picture {
                position: relative;

                &.border {
                    border: solid 20px #ffffff;
                    border-bottom: none;
                    margin-top: -160px;
                    z-index: 2;
                }
            }

            .box-whatsapp {
                margin: -20px 0 0;
                position: relative;
                width: 100%;
                z-index: 2;
            }
        }

        .box-about-gallery {

            >.title {
                font-size: 30px;
                margin: 0 0 30px;
            }

            >.list-items {
                margin: 0 0 0 45px;
                max-width: 485px;
                position: relative;
                width: 100%;

                .swiper-slide {
                    > a {
                        display: block;
                        margin: 0 auto;
                        max-width: 235px;
                        position: relative;
                        width: 100%;

                        &::before,
                        &::after {
                            content: "";
                            opacity: 0;
                            position: absolute;
                            @include transition;
                        }

                        &::before {
                            background-color: $brand-color;
                            left: 0;
                            top: 0;
                            @include size(100%);
                            z-index: 1;
                        }

                        &::after {
                            @include imageBg(
                                $image: "zoom.svg",
                                $size: contain
                            );
                            left: calc(50% - 15px);
                            top: calc(50% - 15px);
                            transform: translateY(15px);
                            @include size(30px);
                            z-index: 2;
                        }

                        &:hover {
                            &::before {
                                opacity: 0.9;
                            }

                            &::after {
                                opacity: 1;
                                transform: translateY(0);
                            }
                        }
                    }
                }

                > span {
                    @include imageBg;
                    border-radius: 3px;
                    cursor: pointer;
                    display: block;
                    position: absolute;
                    text-align: left;
                    text-indent: -9999px;
                    top: calc(50% - 20px);
                    @include transition;
                    @include size(40px);
    
                    &.button-prev {
                        background-image: url(../images/arrow-carousel-left.png);
                        left: -45px;
                    }
    
                    &.button-next {
                        background-image: url(../images/arrow-carousel-right.png);
                        right: -45px;
                    }
    
                    &:hover {
                        background-color: #f2f2f2;
                        box-shadow: 0 3px 15px rgba(#000000, 0.03);
                    }
                }
            }
        }

        @include breakpoint-min(xlarge) {
            .box-text {
                margin: 0 0 300px;
                padding-bottom: 40px;
                position: relative;

                &::after {
                    background-color: #f1f1f1;
                    bottom: 0;
                    content: "";
                    position: absolute;
                    left: 15px;
                    @include size(calc(100% - 30px), 2px);
                }
            }

            .box-image {
                margin: -160px 0 0;
            }

            .box-about-gallery {
                margin: -270px 0 0;
            }
        }

        @include breakpoint-max(xlarge) {
            .box-text {
                margin: 0 auto;
                max-width: 600px;
                width: 100%;
            }

            .box-image {
                margin: 50px 0 0;
                width: 100%;
            }

            .box-about-gallery {
                margin: 50px 0 0;
                text-align: center;

                >.list-items {
                    margin: 0 auto;
                }
            }
        }

        @include breakpoint-max(large) {
            .box-image {
                picture {
                    &.border {
                        margin-top: -50px;
                    }
                }
            }
        }

        @include breakpoint-max(medium) {
            .box-about-gallery {
                >.list-items {
                    max-width: 235px;
                }
            }
        }
    }

    .box-vision {
        background-color: #fafafa;
        margin: 110px 0 0;
        padding: 100px 0;

        .row {
            margin-bottom: -80px;
        }

        .item {
            margin: 0 0 80px;

            >.inner {
                position: relative;
                
                > h2 {
                    color: $brand-color;
                    font-size: 48px;
                    padding: 0 0 0 100px;
                    position: relative;

                    &::before {
                        color: rgba(#000000, 0.04);
                        content: attr(data-text);
                        font-size: 190px;
                        font-weight: weight(extrabold);
                        line-height: 1em;
                        position: absolute;
                        left: 0;
                        top: calc(50% - 95px);
                    }
                }

                >.box-text {
                    padding: 0 0 0 170px;

                    > h3 {
                        color: #333333;
                        font-size: 24px;
                        margin: 0 0 20px;
                    }

                    > ul {
                        @include flex(
                            $align: flex-start
                        );

                        li {
                            width: 50%;
                        }
                    }
                }
            }

            >.frase {
                background-color: #ffffff;
                color: $brand-color;
                padding: 40px;

                > p {
                    font-size: 17px;
                    line-height: em(24, 17);
                }
            }
        }

        @include breakpoint-max(large) {
            margin: 50px 0 0;
            padding: 60px 0;

            .item {
                width: 100%;

                >.inner {
                    margin: 0 auto;
                    max-width: 570px;
                    width: 100%;

                    > h2 {
                        font-size: 36px;
                        margin: 0 0 10px;
                        padding: 0;

                        &::before {
                            bottom: 100%;
                            font-size: 72px;
                            line-height: 0.8em;
                            margin: 0 0 -20px;
                            top: auto;
                        }
                    }

                    .box-text {
                        padding: 0;
                    }
                }

                >.frase {
                    margin: -30px auto 0;
                    max-width: 570px;
                    padding: 30px;
                    width: 100%;
                }
            }
        }
    }
}