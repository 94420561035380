.site-header {
    border-bottom: solid 4px $brand-color;
    padding: 20px 0 40px;

    &-top {
        margin: 0 0 30px;

        .contacts {
            .item {
                & + .item {
                    margin: 0 0 0 30px;
                }

                > a {
                    color: $text-color-dark;
                    @include flex(
                        $align: center,
                        $justify: flex-start
                    );
                    font-size: 18px;
                    line-height: 1em;
                    @include transition;

                    > i {
                        color: $brand-color;
                        margin: 0 10px 0 0;
                    }

                    &:hover {
                        color: $brand-color;
                    }
                }
            }
        }

        .social-media {
            > li {
                & + li {
                    margin: 0 0 0 5px;
                }

                > a {
                    background-color: #ffffff;
                    border-radius: 50%;
                    color: $brand-color;
                    @include flex(
                        $align: center,
                        $justify: center
                    );
                    font-size: 18px;
                    line-height: 1em;
                    @include transition;
                    @include size(30px);

                    &:hover {
                        background-color: $brand-color;
                        color: #ffffff;
                    }
                }
            }
        }

        @include breakpoint-min(large) {
            .inner {
                border-bottom: solid 1px $border-color;
                padding: 0 0 30px;
            }
        }

        @include breakpoint-max(large) {
            .contacts {
                flex-direction: column;
                align-items: flex-start;

                .item {
                    & + .item {
                        margin: 10px 0 0;
                    }

                    > a {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    &-content {
        ul {
            @include flex(
                $align: center
            );

            > li {
                & + li {
                    margin: 0 0 0 30px;
                }

                > a {
                    color: $text-color-dark;
                    display: block;
                    font-size: 18px;
                    font-weight: weight(light);
                    @include transition;

                    &:hover {
                        color: $brand-color;
                    }
                }

                &.active {
                    > a {
                        color: $brand-color;
                    }
                }
            }
        }

        .btn-toggle-menu {
            background-color: #ffffff;
            border: solid 1px #cfcfcf;
            border-radius: 50%;
            @include flex(
                $align: center,
                $justify: center
            );
            @include transition;
            @include size(50px);

            > i {
                color: $brand-color;
                font-size: 18px;
                @include transition;
            }

            &:hover {
                background-color: $brand-color;
                border-color: $brand-color;

                > i {
                    color: #ffffff;
                }
            }
        }

        @include breakpoint-min(large) {
            .btn-toggle-menu {
                display: none !important;
            }
        }

        @include breakpoint-max(xlarge) {
            .logo {
                max-width: 300px;
            }
        }

        @include breakpoint-max(large) {
            ul {
                display: none;
            }
        }

        @include breakpoint-max(small) {
            .logo {
                max-width: 220px;
            }
        }
    }

    &-fixed {
        background-color: #ffffff;
        border-bottom: solid 4px $brand-color;
        left: 0;
        padding: 20px 0;
        position: fixed;
        top: -100%;
        @include transition;
        width: 100%;
        z-index: 5;

        &.show {
            top: 0;
            transition-delay: 0.1s;
        }

        .contacts {
            .item {
                & + .item {
                    margin: 0 0 0 20px;
                }

                > a {
                    color: $text-color-dark;
                    @include flex(
                        $align: center,
                        $justify: flex-start
                    );
                    font-size: 15px;
                    line-height: 1em;
                    @include transition;

                    > i {
                        color: $brand-color;
                        font-size: 18px;
                        margin: 0 10px 0 0;
                    }

                    &:hover {
                        color: $brand-color;
                    }
                }
            }
        }

        .logo {
            @include flex(
                $justify: center
            );
        }

        .menu {
            > ul {
                @include flex(
                    $align: center,
                    $justify: flex-end
                );

                > li {
                    & + li {
                        margin: 0 0 0 15px;
                    }

                    > a {
                        font-size: 16px;
                        @include transition;

                        &:hover {
                            color: $brand-color;
                        }
                    }

                    &.active {
                        > a {
                            color: $brand-color;
                        }
                    }
                }
            }
        }

        @include breakpoint-max(xlarge) {
            padding: 10px 0;

            .contacts {
                flex-direction: column;
                align-items: flex-start;

                .item {
                    & + .item {
                        margin: 10px 0 0;
                    }
                }
            }

            .menu {
                > ul {
                    > li {
                        & + li {
                            margin: 0 0 0 10px;
                        }

                        > a {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        @include breakpoint-max(large) {
            display: none !important;
        }
    }

    &-mobile {
        background-color: rgba(#1a1a1a, 0.95);
        left: 0;
        overflow: auto;
        padding: 30px 15px;
        position: fixed;
        top: 0;
        transform: translateX(-100%);
        @include transition(
            $duration: 0.45s
        );
        @include size(100%);
        z-index: 5;

        &.show {
            transform: translateX(0);
        }

        .inner {
            @include flex(
                $direction: column,
                $align: flex-start
            );
            position: relative;
        }

        .btn-close-menu {
            background-color: #ffffff;
            @include imageBg(
                $image: "icon-close-menu.png"
            );
            border-radius: 3px;
            padding: 0;
            position: absolute;
            right: 0;
            text-align: left;
            text-indent: -9999px;
            top: 3px;
            @include transition;
            @include size(45px, 35px);

            &:hover {
                background-color: $brand-color;
            }
        }

        .logo {
            margin: 0 0 60px;
        }

        .menu {
            margin: 0 0 40px;

            ul {
                @include flex(
                    $direction: column,
                    $align: flex-start
                );

                > li {
                    & + li {
                        margin: 20px 0 0;
                    }
    
                    > a {
                        color: #ffffff;
                        display: block;
                        font-size: 18px;
                        font-weight: weight(light);
                    }

                    &.active {
                        > a {
                            color: $brand-color;
                            font-weight: weight(medium);
                        }
                    }
                }
            }
        }

        .contacts {
            @include flex(
                $direction: column,
                $align: flex-start
            );
            margin: 0 0 40px;

            .item {
                & + .item {
                    margin: 10px 0 0;
                }

                > a {
                    color: #ffffff;
                    @include flex(
                        $align: center
                    );
                    font-size: 18px;
                    @include transition;

                    > i {
                        color: $brand-color;
                        display: block;
                        font-size: 20px;
                        margin: 0 10px 0 0;
                    }

                    &:hover {
                        color: $brand-color;
                    }
                }
            }
        }

        .social-media {
            > li {
                & + li {
                    margin: 0 0 0 5px;
                }

                > a {
                    border-radius: 50%;
                    color: $brand-color;
                    @include flex(
                        $align: center,
                        $justify: center
                    );
                    font-size: 18px;
                    line-height: 1em;
                    @include transition;
                    @include size(30px);

                    &:hover {
                        background-color: $brand-color;
                        color: #ffffff;
                    }
                }
            }
        }

        @include breakpoint-min(large) {
            display: none !important;
        }
    }
}