.site-footer {
    background-color: #2d2d2d;

    &-content {
        padding: 60px 0 80px;

        .footer-logo {
            border-bottom: solid 1px rgba(#ffffff, 0.1);
            @include flex(
                $align: center,
                $justify: center
            );
            margin: 0 0 50px;
            padding: 0 0 50px;

            > a {
                display: block;
            }
        }

        .footer-title {
            color: #ffffff;
            font-size: 20px;
            margin: 0 0 20px;
        }

        .footer-menu {
            >.inner {
                max-width: 270px;
                width: 100%;
            }

            ul {
                @include flex(
                    $align: flex-start
                );

                > li {
                    margin: 0 0 20px;
                    width: 50%;

                    > a {
                        color: #ffffff;
                        display: table;
                        font-size: 16px;
                        font-weight: weight(thin);
                        @include transition;

                        &:hover {
                            color: $brand-color;
                        }
                    }

                    &.active {
                        > a {
                            color: $brand-color;
                        }
                    }
                }
            }
        }

        .footer-address {
            >.inner {
                @include flex(
                    $direction: column,
                    $align: flex-start
                );
                max-width: 295px;
                width: 100%;
            }

            p {
                color: #ffffff;
                font-size: 18px;
                font-weight: weight(thin);
                line-height: 1.2em;

                & + p {
                    margin: 10px 0 0;
                }
            }

            a {
                border: solid 1px $brand-color;
                border-radius: 3px;
                color: $brand-color;
                @include flex(
                    $align: center,
                    $justify: center
                );
                font-size: 15px;
                font-weight: weight(regular);
                margin: 20px 0 0;
                padding: 12px 15px;
                text-transform: uppercase;
                @include transition;

                > i {
                    display: block;
                    font-size: 12px;
                    margin: 0 0 0 10px;
                }

                &:hover {
                    background-color: $brand-color;
                    color: #ffffff;
                }
            }
        }

        .footer-contacts {
            @include flex(
                $justify: flex-end
            );

            .item {
                & + .item {
                    margin: 20px 0 0;
                }

                > a {
                    color: #ffffff;
                    @include flex(
                        $align: center
                    );
                    font-size: 18px;
                    font-style: italic;
                    font-weight: weight(light);
                    min-height: 40px;
                    padding: 0 0 0 50px;
                    position: relative;
                    text-transform: uppercase;
                    @include transition;

                    > strong {
                        font-weight: weight(light);
                        margin: 0 10px 0 0;
                    }

                    > i {
                        border: solid 2px $brand-color;
                        border-radius: 50%;
                        color: $brand-color;
                        @include flex(
                            $align: center,
                            $justify: center
                        );
                        left: 0;
                        position: absolute;
                        top: 0;
                        @include transition;
                        @include size(40px);
                    }

                    &:hover {
                        color: $brand-color;

                        > i {
                            background-color: $brand-color;
                            color: #ffffff;
                        }
                    }
                }
            }
        }

        @include breakpoint-max(large) {
            .footer-menu,
            .footer-address,
            .footer-contacts {
                align-items: center;
                flex-direction: column;
                width: 100%;

                & + div {
                    margin: 40px 0 0;
                }

                >.inner {
                    @include flex(
                        $direction: column,
                        $align: center
                    );
                    max-width: none;
                }
            }

            .footer-menu {
                ul {
                    align-items: center;
                    flex-direction: column;

                    > li {
                        margin: 0;
                        width: auto;

                        & + li {
                            margin: 5px 0 0;
                        }
                    }
                }
            }

            .footer-contacts {
                >.inner {
                    align-items: flex-start;
                }

                .item {
                    > a {
                        font-size: 17px;
                    }
                }
            }
        }
    }

    &-copyright {
        background-color: #262626;
        padding: 40px 0;

        p {
            color: #ffffff;
            font-size: 15px;
            font-weight: weight(regular);
            line-height: 1.2em;
            text-transform: uppercase;
        }

        a {
            display: block;
            opacity: 1;
            @include transition;

            &:hover {
                opacity: 0.5;
            }
        }

        @include breakpoint-max(medium) {
            padding: 20px 0;
            
            .container {
                flex-direction: column;
            }

            a {
                margin: 20px 0 0;
            }
        }
    }
}