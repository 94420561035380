.page-campaign {
    .page-header {
        @include linearGradient(
            $color1: #fafafa,
            $color2: #ffffff,
            $angle: to bottom
        );
        border: none;

        .page-title {
            font-size: 120px;
            line-height: 1em;
        }
    }

    .list-items {
        >.row {
            margin-bottom: -90px;
        }

        .item {
            margin: 0 0 90px;

            .item-content {
                .inner {
                    h2 {
                        color: $brand-color;
                        font-size: 36px;
                        font-weight: weight(extralight);
                        font-style: italic;
                    }
                }
            }
        }
    }

    .page-gallery {
        @include linearGradient(
            $color1: #fafafa,
            $color2: #ffffff,
            $angle: to bottom
        );
        margin: 40px 0 0;
        padding: 80px 0 120px;

        .box-title {
            margin: 0 0 30px;

            .title {
                color: $brand-color;
                font-size: 60px;
                font-weight: weight(light);

                > span {
                    color: $text-color-dark;
                }
            }

            .subtitle {
                color: $brand-color;
                font-size: 20px;
                font-weight: weight(extralight);
            }
        }

        .swiper-slide {
            > a {
                display: block;
                margin: 0 auto;
                max-width: 370px;
                position: relative;
                width: 100%;

                &::before,
                &::after {
                    content: "";
                    opacity: 0;
                    position: absolute;
                    @include transition;
                }

                &::before {
                    background-color: $brand-color;
                    left: 0;
                    top: 0;
                    @include size(100%);
                    z-index: 1;
                }

                &::after {
                    @include imageBg(
                        $image: "zoom.svg",
                        $size: contain
                    );
                    left: calc(50% - 15px);
                    top: calc(50% - 15px);
                    transform: translateY(15px);
                    @include size(30px);
                    z-index: 2;
                }

                &:hover {
                    &::before {
                        opacity: 0.9;
                    }

                    &::after {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }
        }

        .gallery-navigation {
            margin: 30px 0 0;
            @include flex(
                $align: center,
                $justify: center
            );

            > div {
                @include imageBg;
                cursor: pointer;
                opacity: 1;
                text-align: left;
                text-indent: -9999px;
                @include transition;
                @include size(30px);

                & + div {
                    margin: 0 0 0 10px;
                }

                &.button-prev {
                    background-image: url(../images/arrow-left.png);
                }

                &.button-next {
                    background-image: url(../images/arrow-right.png);
                }

                &:hover {
                    opacity: 0.75;
                }
            }
        }

        .box-button {
            @include flex(
                $align: center,
                $justify: center
            );
            margin: 70px 0 0;

            a {
                border: solid 1px $brand-color;
                border-radius: 3px;
                color: $brand-color;
                @include flex(
                    $align: center,
                    $justify: center
                );
                font-size: 15px;
                font-weight: weight(regular);
                max-width: 310px;
                padding: 15px;
                text-transform: uppercase;
                width: 100%;
                @include transition;

                &::after {
                    content: "\f054";
                    display: block;
                    font-family: 'Font Awesome 5 Free';
                    font-size: 12px;
                    font-weight: 900;
                    margin: 0 0 0 15px;
                }

                > i {
                    display: block;
                    font-size: 12px;
                    margin: 0 0 0 10px;
                }

                &:hover {
                    background-color: $brand-color;
                    color: #ffffff;
                }
            }
        }
    }

    @include breakpoint-min(large) {
        .list-items {
            .item {
                >.row {
                    align-items: flex-end;
    
                    .item-image {
                        margin: 0 0 80px;
                        position: relative;
                        z-index: 2;
                        
                        > img {
                            box-shadow: 0 0 40px rgba(#000000, 0.12);
                        }
                    }
    
                    .item-content {
                        .inner {
                            background-color: #fafafa;
                            padding: 70px 70px 130px 70px;
                            position: relative;
                            z-index: 1;
    
                            &::before {
                                background-color: #fafafa;
                                content: "";
                                position: absolute;
                                right: 0;
                                top: 0;
                                @include size(calc(100% + 295px), 100%);
                                z-index: -1;
                            }

                            &::after {
                                @include imageBg(
                                    $image: "folha.png"
                                );
                                content: "";
                                position: absolute;
                                bottom: calc(100% + 55px);
                                left: 0;
                                @include size(205px, 155px);
                            }
                        }
                    }
                }
    
                &.featured {
                    &:nth-child(2n+2) {
                        >.row {
                            flex-direction: row-reverse;
    
                            .item-content {
                                .inner {
                                    &::before {
                                        left: 0;
                                        right: auto;
                                    }
                                }
                            }
                        }
                    }
                }

                &:not(.featured) {
                    .item-image {
                        position: relative;

                        &::before {
                            @include imageBg(
                                $image: "bloquinhos.png"
                            );
                            content: "";
                            right: -30px;
                            position: absolute;
                            bottom: -70px;
                            @include size(136px);
                        }
                    }
                    .item-content {
                        .inner {
                            padding: 30px 0 0 90px;

                            h2 {
                                font-size: 30px;
                                max-width: 300px;
                            }
                        }
                    }
                }
            }
        }
    }

    @include breakpoint-max(xlarge) {
        .list-items {
            .item {
                >.row {
                    .item-content {
                        .inner {
                            padding: 50px 50px 130px 50px;

                            &::after {
                                bottom: calc(100% + 15px);
                            }
                        }
                    }
                }
            }
        }
    }

    @include breakpoint-max(large) {
        .page-header {
            .page-title {
                font-size: 48px;
            }
        }

        .list-items {
            >.row {
                margin-bottom: -120px;
            }

            .item {
                margin: 0 auto 120px;
                max-width: 570px;
                width: 100%;

                .item-image {
                    position: relative;
                    width: 100%;
                    z-index: 1;

                    &::before {
                        @include imageBg(
                            $image: "folha.png"
                        );
                        content: "";
                        position: absolute;
                        top: -70px;
                        right: -15px;
                        @include size(205px, 155px);
                        z-index: -1;
                    }
                        
                    > img {
                        box-shadow: 0 0 40px rgba(#000000, 0.12);
                    }
                }

                .item-content {
                    padding: 0 10px;
                    width: 100%;
                    
                    .inner {
                        background-color: #f8f8f8;
                        padding: 20px 30px !important;
                        text-align: center;

                        h2 {
                            font-size: 24px;
                        }
                    }
                }
            }
        }

        .page-gallery {
            .box-title {
                .title {
                    font-size: 36px;
                }
            }
        }
    }

    @include breakpoint-max(small) {
        .page-gallery {
            .box-title {
                margin: 0 auto 30px;
                max-width: 370px;
                width: 100%;

                .title {
                    font-size: 30px;
                }
            }
        }
    }
}