.box-text {
    p {
        font-size: 17px;
        line-height: em(30, 17);

        & + p {
            margin: 20px 0 0;
        }

        strong {
            color: $brand-color;
            font-weight: weight(light);
        }
    }

    ul {
        li {
            font-size: 17px;
            font-weight: weight(regular);
            line-height: 1.45em;
            margin: 0 0 10px;
            padding: 0 0 0 28px;
            position: relative;

            &::before {
                @include imageBg(
                    $image: "check-list.png"
                );
                content: "";
                left: 0;
                position: absolute;
                top: 1px;
                @include size(20px);
            }
        }
    }
}