.box-lookbook-item {
    margin: 0 0 50px;

    > a {
        display: block;
        margin: 0 auto;
        max-width: 270px;
        position: relative;
        width: 100%;
        
        >.item-image {
            box-shadow: 0 0 0 1px $border-color;
            position: relative;

            >.item-code-button {
                background-color: rgba(#f0f0f0, 0.9);
                @include flex(
                    $direction: column,
                    $align: center,
                    $justify: center
                );
                left: 0;
                opacity: 0;
                position: absolute;
                top: 0;
                @include size(100%);
                @include transition(
                    $duration: 0.45s
                );
                transition-delay: 0.3s;
                z-index: 1;

                > span {
                    color: $brand-color;
                    display: block;
                    line-height: 1em;
                    opacity: 0;
                    transform: translateY(10px);
                    @include transition;

                    &.code {
                        font-size: 16px;
                        margin: 0 0 20px;
                        transition-delay: 0.15s;
                    }

                    &.button {
                        border: solid 1px $brand-color;
                        border-radius: 3px;
                        @include flex(
                            $align: center,
                            $justify: center
                        );
                        font-size: 15px;
                        padding: 15px 30px;
                        text-transform: uppercase;
                        transform: translateY(10px);
                        transition-delay: 0.25s;

                        &::after {
                            content: "\f31e";
                            display: block;
                            font-family: 'Font Awesome 5 Free';
                            font-weight: 900;
                            font-size: 14px;
                            line-height: 1em;
                            margin: 0 0 0 10px;
                        }
                    }
                }
            }
        }

        >.item-title {
            color: $brand-color;
            font-size: 22px;
            font-weight: weight(extralight);
            margin: 20px 0 0;
            text-align: center;
        }

        &:hover {
            >.item-image {
                >.item-code-button {
                    opacity: 1;
                    transition-delay: 0s;
                    > span {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }
        }
    }
}