.box-instagram {
    margin: 0 auto;
    max-width: 1920px;
    width: 100%;

    .list-items {
        @include flex(
            $align: flex-start,
            $justify: center
        );
        margin: 0 0 -2px;

        >.item {
            margin: 0 0 2px;
            position: relative;
            width:16.66666667%;

            &::after {
                background-color: #ffffff;
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                @include size(2px, 100%);
                z-index: 1;
            }

            > a {
                display: block;
                position: relative;

                &::before,
                &::after {
                    content: "";
                    opacity: 0;
                    position: absolute;
                    @include transition;
                }

                &::before {
                    background-color: $brand-color;
                    left: 0;
                    top: 0;
                    @include size(100%);
                    z-index: 1;
                }

                &::after {
                    @include imageBg(
                        $image: "zoom.svg",
                        $size: contain
                    );
                    left: calc(50% - 15px);
                    top: calc(50% - 15px);
                    transform: translateY(15px);
                    @include size(30px);
                    z-index: 2;
                }

                &:hover {
                    &::before {
                        opacity: 0.9;
                    }

                    &::after {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }
        }
    }

    @include breakpoint-max(medium) {
        .list-items {
            .item {
                width: 33.33%;
            }
        }
    }

    @include breakpoint-max(small) {
        .list-items {
            .item {
                width: 50%;
            }
        }
    }
}