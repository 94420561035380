// Home Slide
.home-slide {
    position: relative;

    .swiper-slide {
        @include imageBg(
            $size: cover,
            $repeat: no-repeat
        );
        height: 1010px;
    }

    .slide-pagination {
        bottom: 60px;
        @include flex(
            $align: center,
            $justify: center
        );
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 3;

        >.bullet {
            background-color: #dddbd9;
            border-radius: 50%;
            display: block;
            @include transition;
            @include size(12px);

            & + .bullet {
                margin: 0 0 0 20px;
            }

            &-active {
                background-color: $brand-color;
                box-shadow: 0 0 0 3px $brand-color;
            }
        }
    }

    @include breakpoint-max(xlarge) {
        .swiper-slide {
            height: 850px;
        }
    }

    @include breakpoint-max(large) {
        .swiper-slide {
            height: 550px;
        }

        .slide-pagination {
            bottom: 30px;
        }
    }

    @include breakpoint-max(small) {
        .swiper-slide {
            height: 240px;
        }
    }
}

// Home Lookbook
.home-lookbook {
    margin: 80px auto 0;
    max-width: 1920px;
    width: 100%;

    .container {
        .title {
            color: $text-color-dark;
            font-size: 30px;
            font-weight: weight(extralight);
            letter-spacing: 10px;
            margin: 0 0 40px;
            padding: 0 0 40px;
            position: relative;
    
            > span {
                color: $brand-color;
            }
    
            &::after {
                @include imageBg(
                    $image: "pattern-shopkeeper.jpg",
                    $pos: center 0
                );
                bottom: 0;
                content: "";
                left: 0;
                position: absolute;
                @include size(100%, 12px)
            }
        }
    }

    .box-lookbook-item {
        margin: 0;

        > a {
            max-width: 475px;

            >.item-image {
                @include linearGradient(
                    $color1: #cccccc,
                    $color2: #ffffff,
                    $angle: to top
                );
                box-shadow: none;
                padding: 0 1px 1px;

                >.item-title {
                    bottom: 30px;
                    color: $text-color-dark;
                    font-size: 22px;
                    font-weight: weight(regular);
                    left: 30px;
                    position: absolute;
                    z-index: 2;
                }
            }
        }
    }

    .lookbook-navigation {
        margin: 30px 0 0;
        @include flex(
            $align: center,
            $justify: center
        );

        > div {
            @include imageBg;
            cursor: pointer;
            opacity: 1;
            text-align: left;
            text-indent: -9999px;
            @include transition;
            @include size(30px);

            & + div {
                margin: 0 0 0 10px;
            }

            &.button-prev {
                background-image: url(../images/arrow-left.png);
            }

            &.button-next {
                background-image: url(../images/arrow-right.png);
            }

            &:hover {
                opacity: 0.75;
            }
        }
    }

    @include breakpoint-min(1920px) {
        .box-lookbook-item {
            > a {
                >.item-image {
                    >.item-title {
                        bottom: 80px;
                        left: 80px;
                    }
                }
            }
        }
    }

    @include breakpoint-max(large) {
        margin: 40px 0 0;
        padding: 0 15px;

        .container {
            padding: 0;

            .title {
                font-size: 24px;
                letter-spacing: 0;
            }
        }

        .box-lookbook-item {
            > a {
                >.item-image {
                    >.item-title {
                        bottom: 20px;
                        font-size: 16px;
                        left: 20px;
                    }
                }
            }
        }
    }
}

// Home About
.home-about {
    margin: 220px 0 0;

    .box-content {

        .box-title {
            margin: 0 0 30px;
            position: relative;

            &::before {
                @include imageBg(
                    $image: "favicon.png"
                );
                content: "";
                position: absolute;
                left: 0;
                top: -80px;
                @include size(40px, 22px);
            }

            >.upper-title {
                color: $brand-color;
                font-size: 20px;
                font-weight: weight(extralight);
                line-height: 1.2em;
            }

            > h2 {
                color: $text-color-dark;
                font-size: 90px;
                line-height: 1em;

                > span {
                    color: $brand-color;
                }
            }

            > h3 {
                color: $text-color-dark;
                font-size: 30px;
                margin: 10px 0 0;
                text-transform: uppercase;
            }
        }

        .box-link {
            margin: 30px 0 0;
            @include flex();

            > a {
                color: $brand-color;
                @include flex(
                    $align: center
                );
                font-size: 17px;
                font-weight: weight(medium);
                text-transform: uppercase;
                @include transition;

                &::before {
                    background-color: $brand-color;
                    @include imageBg(
                        $image: "icon-plus.png"
                    );
                    border-radius: 3px;
                    content: "";
                    display: block;
                    margin: 0 20px 0 0;
                    @include transition;
                    @include size(50px)
                }

                &:hover {
                    color: $text-color-dark;

                    &::before {
                         background-color: $text-color-dark;
                    }
                }
            }
        }
    }

    .box-image {
        @include flex(
            $direction: column,
            $align: flex-start
        );
        position: relative;
        z-index: 3;
    }

    @include breakpoint-min(large) {
        .box-content {
            .inner {
                padding: 70px 0 120px;
                position: relative;
                z-index: 1;
    
                &::before {
                    background-color: #fafafa;
                    content: "";
                    position: absolute;
                    right: -30px;
                    top: 0;
                    @include size(1000%, 100%);
                    z-index: -1;
                }
                
                &::after {
                    @include imageBg(
                        $image: "pattern-shopkeeper.jpg",
                        $pos: center 0
                    );
                    top: -12.5px;
                    content: "";
                    right: -30px;
                    position: absolute;
                    @include size(calc(100% - 40px), 25px)
                }
            }
        }

        .box-image {
            margin: -130px 0 -220px;

            picture {
                border-color: #ffffff;
                border-style: solid;
                border-width: 30px 30px 30px 0;
                display: block;
                position: relative;

                &:first-child {
                    &::before {
                        @include imageBg(
                            $image: "bloquinhos.png"
                        );
                        content: "";
                        left: -95px;
                        position: absolute;
                        top: -70px;
                        @include size(136px);
                    }
                }
    
                &.border {
                    border-width: 30px 30px 0 0;
                    left: -160px;
                    position: absolute;
                    top: calc(100% - 230px);
                }
            }
        }
    }

    @include breakpoint-max(xlarge) {
        .box-content {
            .box-title {
                > h2 {
                    font-size: 65px;
                }

                > h3 {
                    font-size: 24px;
                }
            }
        }
        .box-image {
            picture {
                &.border {
                    left: -110px;
                }
            }
        }
    }

    @include breakpoint-max(large) {
        margin: 60px 0 0;

        .box-content {
            background-color: #fafafa;
            width: 100%;
            
            .inner {
                margin: 0 auto;
                max-width: 340px;
                padding: 50px 0 180px;
                width: 100%;
            }

            .box-title {
                &::before {
                    top: -60px;
                }

                > h2 {
                    font-size: 48px;
                }

                > h3 {
                    font-size: 18px;
                }
            }
        }

        .box-image {
            align-items: center;
            margin: -130px 0 0;
            width: 100%;

            picture {
                &.border {
                    border-color: #ffffff;
                    border-style: solid;
                    border-width: 20px 20px 0;
                    left: auto;
                    margin: -50px 0 0;
                    position: relative;
                    z-index: 2;
                }
            }
        }
    }

    @include breakpoint-max(small) {
        .box-image {
            picture {
                &.border {
                    border-width: 10px 10px 0;
                }
            }
        }
    }
}

// Home Shopkeeper
.home-shopkeeper {
    @include imageBg(
        $image: "bg-lojista-home.jpg",
        $size: cover
    );
    height: 960px;
    margin: 0 auto;
    max-width: 1640px;
    width: 100%;

    >.container {
        @include flex(
            $align: flex-end,
            $justify: flex-end
        );
        height: 100%;
        position: relative;
    }

    .box-chat-whatsapp {
        @include imageBg(
            $image: "bg-chat-whatsapp.jpg",
            $size: cover
        );
        left: 15px;
        max-width: 270px;
        padding: 20px;
        position: absolute;
        top: -50px;
        width: 100%;
        z-index: 2;

        >.inner {
            background-color: rgba(#ffffff, 0.8);
            @include flex(
                $align: center,
                $direction: column
            );
            padding: 30px;
            text-align: center;

            > i.fa-whatsapp {
                border: solid 2px $brand-color;
                border-radius: 50%;
                color: $brand-color;
                @include flex(
                    $align: center,
                    $justify: center
                );
                font-size: 20px;
                margin: 0 0 20px;
                @include size(44px);
            }

            > h2 {
                color: $text-color-dark;
                font-size: 24px;
                font-weight: weight(extralight);
                margin: 0 0 15px;

                > span {
                    display: block;
                    font-weight: weight(regular);
                }
            }

            > p {
                color: $brand-color;
                font-size: 20px;
                line-height: 1.2em;
                font-weight: weight(extralight);
            }
        
            > a {
                background-color: transparent;
                border: solid 1px $brand-color;
                border-radius: 3px;
                color: $brand-color;
                cursor: pointer;
                @include flex(
                    $justify: center,
                    $align: center
                );
                font-size: 15px;
                font-weight: weight(regular);
                line-height: 1.25em;
                margin: 20px 0 0;
                overflow: hidden;
                padding: 12px 15px;
                position: relative;
                text-transform: uppercase;
                @include transition;
                width: 100%;
                z-index: 1;

                > i {
                    display: block;
                    font-size: 12px;
                    margin: 0 0 0 20px;
                }

                &:hover {
                    background-color: $brand-color;
                    color: #ffffff;
                }
            }
        }
    }

    .content {
        @include flex(
            $direction: column,
            $align: flex-start
        );
        margin: 0 85px 180px 0;

        > h2 {
            color: $brand-color;
            font-size: 150px;
            font-weight: weight(thin);
        }

        > p {
            color: #2d2626;
            font-size: 30px;
            font-weight: weight(light);
            line-height: 1.25em;
        }

        
    
        > a {
            background-color: #000000;
            border-radius: 3px;
            color: #ffffff;
            cursor: pointer;
            @include flex(
                $justify: center,
                $align: center
            );
            font-size: 15px;
            font-weight: weight(regular);
            line-height: 1.25em;
            margin: 30px 0 0;
            overflow: hidden;
            padding: 16px 35px;
            position: relative;
            text-transform: uppercase;
            @include transition;
            z-index: 1;

            &::before {
                @include linearGradient(
                    $angle: to top
                );
                content: "";
                left: 0;
                opacity: 1;
                position: absolute;
                top: 0;
                @include transition;
                @include size(100%);
                z-index: -1;
            }

            > i {
                display: block;
                font-size: 12px;
                margin: 0 0 0 20px;
            }

            &:hover {
                &::before {
                    opacity: 0;
                }
            }
        }
    }

    img.featured {
        position: absolute;
        bottom: calc(100% - 360px);
        left: calc(100% - 345px);
        z-index: 2;
    }

    @include breakpoint-max(xlarge) {
        img.featured {
            display: none !important;
        }
    }

    @include breakpoint-max(large) {
        background-position: 0 0;
        height: 380px;
        margin: 240px 0 0;
        padding: 0 0 80px;
        position: relative;

        &::before {
            @include linearGradient(
                $color1: rgba(#ffffff, 0.85),
                $color2: rgba(#ffffff, 0.5),
                $angle: to left
            );
            content: "";
            left: 0;
            top: 0;
            position: absolute;
            @include size(100%);
            z-index: 1;
        }

        >.container {
            align-items: flex-end;
            justify-content: center;
            position: relative;
            z-index: 2;
        }

        .box-chat-whatsapp {
            top: auto;
            bottom: calc(100% - 85px);
            left: calc(50% - 130px);
            width: 260px;
        }

        .content {
            margin: 0;

            > h2 {
                font-size: 48px;
            }

            > p {
                font-size: 18px;
            }

            > a {
                margin: 10px 0 0;
            }
        }
    }
}

// Home Infos
.home-infos {
    .item-contacts {
        margin: 0 0 -110px;
        position: relative;
        z-index: 3;

        >.inner {
            @include imageBg(
                $image: "bg-home-infos.jpg",
                $size: cover,
                $pos: center 0
            );
            background-color: $brand-color;
            @include flex(
                $direction: column,
                $align: flex-start
            );
            padding: 60px 60px 80px 70px;

            > h2 {
                color: #ffffff;
                font-size: 30px;
                font-weight: weight(regular);
                margin: 0 0 40px;
                padding: 0 0 40px;
                position: relative;
                text-transform: uppercase;

                &::before {
                    background-color: #d4b8be;
                    bottom: 0;
                    content: "";
                    left: 0;
                    position: absolute;
                    @include size(30px, 1px)
                }

                > span {
                    display: block;
                }
            }

            > a {
                color: #ffffff;
                @include flex(
                    $align: center,
                    $wrap: nowrap
                );
                font-size: 24px;
                font-weight: weight(regular);
                line-height: 1.2em;
                position: relative;
                @include transition;
                word-break: break-all;
                text-transform: uppercase;

                & + a {
                    margin: 20px 0 0;
                }

                &::before {
                    @include imageBg;
                    content: "";
                    display: block;
                    margin: 0 10px 0 0;
                    min-width: 40px;
                    transform: rotate(0deg);
                    @include transition(
                        $duration: 0.5s
                    );
                    @include size(40px);
                }

                > strong {
                    color: $text-color-dark;
                    font-weight: weight(regular);
                    margin: 0 10px 0 0;
                    @include transition;
                }
    
                &.phone {
                    &::before {
                        background-image: url(../images/icons-contacts-home-phone.png);
                    }
                }
    
                &.email {
                    text-transform: lowercase;
                    &::before {
                        background-image: url(../images/icons-contacts-home-email.png);
                    }
                }

                &:hover {
                    color: $text-color-dark;

                    &::before {
                        transform: rotate(360deg);
                    }

                    > strong {
                        color: $brand-color;
                    }
                }
            }
        }
    }
    
    .item-social-media {
        margin: 40px 0 0;
        position: relative;

        &::after {
            @include imageBg(
                $image: "pattern.png",
                $pos: 0 0,
                $repeat: repeat
            );
            content: "";
            right: 15px;
            position: absolute;
            bottom: 0;
            @include size(100%, 80px);
        }

        .inner {
            @include flex(
                $align: center
            );
            padding: 0 0 0 40px;

            > p {
                color: #333333;
                font-size: 20px;
                line-height: 1.2em;
                text-transform: uppercase;
            }

            > ul {
                @include flex(
                    $align: center
                );
                margin: 0 0 0 40px;

                > li {
                    & + li {
                        margin: 0 0 0 10px;
                    }

                    > a {
                        @include imageBg;
                        display: block;
                        text-align: left;
                        text-indent: -9999px;
                        transform: rotate(0deg);
                        @include transition;
                        @include size(50px);

                        &.facebook {
                            background-image: url(../images/icons-social-media-contacts-facebook.png);
                        }

                        &.instagram {
                            background-image: url(../images/icons-social-media-contacts-instagram.png);
                        }

                        &:hover {
                            transform: rotate(360deg);
                        }
                    }
                }
            }
        }
    }

    @include breakpoint-max(xlarge) {
        .item-contacts {
            margin: 0 0 -60px;
        }
    }

    @include breakpoint-max(large) {
        padding: 0 0 30px;

        .item-contacts {
            margin: -20px 0 0;
            width: 100%;

            >.inner {
                margin: 0 auto;
                max-width: 570px;
                padding: 25px;
                width: 100%;

                > h2 {
                    font-size: 24px;
                }

                > a {
                    font-size: 15px;
                }
            }
        }

        .item-social-media {
            width: 100%;

            &::after {
                display: none;
            }

            >.inner {
                justify-content: space-between;
                margin: 0 auto;
                max-width: 570px;
                padding: 0;
            }
        }
    }
}