.box-causes-we-support {
    padding: 70px 0;

    .box-title {
        margin: 0 0 30px;

        >.title {
            color: $brand-color;
            font-size: 60px;
            line-height: 1em;

            > span {
                color: $text-color-dark;
            }
        }

        >.upper-title {
            color: $brand-color;
            font-size: 20px;
            font-weight: weight(extralight);
            line-height: 1.25em;
            margin: 0 0 15px;
            padding: 0 0 0 85px;
            position: relative;

            &::before {
                @include imageBg(
                    $image: "icon-parceria.png"
                );
                bottom: 0;
                content: "";
                position: absolute;
                left: 0;
                @include size(55px, 45px);
            }
        }
    }

    .box-items {
        >.inner {
            @include linearGradient(
                $color1: #cccccc,
                $color2: #ffffff,
                $angle: to top
            );
            border-radius: 0 60px;
            overflow: hidden;
            padding: 0 1px 1px;

            >.inner-bg {
                background-color: #f7f7f7;
                border-radius: 0 60px;
                @include flex(
                    $align: center,
                    $justify: center
                );
                padding: 40px 15px;

                >.item {
                    @include flex(
                        $justify: center
                    );

                    & + .item {
                        border-color: #dedede;;
                        border-style: solid;
                        border-width: 0 0 0 1px;
                        margin: 0 0 0 80px;
                        padding: 0 0 0 80px;
                    }

                    > a {
                        display: block;
                        opacity: 1;
                        @include transition;

                        &:hover {
                            opacity: 0.65;
                        }
                    }
                }
            }
        }
    }

    @include breakpoint-max(xlarge) {
        .box-title {
            >.title {
                font-size: 55px;
            }
        }

        .box-items {
            >.inner {
                >.inner-bg {
                    >.item {
                        & + .item {
                            margin: 0 0 0 50px;
                            padding: 0 0 0 50px;
                        }
                    }
                }
            }
        }
    }

    @include breakpoint-max(large) {
        padding: 50px 0;
        
        .box-title {
            text-align: center;
            width: 100%;

            >.upper-title {
                padding: 60px 0 0;

                &::before {
                    bottom: auto;
                    top: 0;
                    left: calc(50% - 27.5px);
                }
            }

            >.title {
                font-size: 48px;

                > span {
                    display: block;
                }
            }
        }

        .box-items {
            margin: 0 auto;
            max-width: 330px;
            width: 100%;

            >.inner {
                >.inner-bg {
                    display: block;

                    >.item {
                        text-align: center;
                        
                        & + .item {
                            border-width: 1px 0 0;
                            margin: 30px 0 0;
                            padding: 30px 0 0;
                        }
                    }
                }
            }
        }
    }
}