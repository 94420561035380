/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
	box-sizing: border-box;
	outline: none !important;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    color: $text-color-dark;
    font-family: $text-font;
    font-size: $font-size-default;
    line-height: 1.2em;
    font-weight: weight(light);
    overflow-y: scroll;
}

// Wrapper
.wrapper {
    overflow: hidden;
}

// Headings
h1, h2, h3, h4, h5, h6 {
    font-weight: weight(light);
    line-height: 1.2em;
    margin: 0
}
h1 {font-size: 25px}
h2 {font-size: 23px}
h3 {font-size: 21px}
h4 {font-size: 19px}
h5 {font-size: 17px}
h6 {font-size: 15px}


// Basic styles for links
a {
	color: $text-color-dark;
	line-height: 1.2em;
    text-decoration: none;
    &:hover {
        color: $brand-color;
    }
}

// Strong
strong, b {
    font-weight: weight(bold);
}


// Img and SVG
img,
svg {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}
img {
    box-sizing: content-box;
}

// Form inputs
input,
select,
textarea,
button {
    appearance: none;
        -webkit-appearance: none;
    border: none;
    box-sizing: border-box;
    box-shadow: none;
    color: $text-color-light;
    display: block;
    font-family: inherit;
    font-size: 16px;
    font-weight: weight(light);
    line-height: 1.150em;
    margin: 0;
    outline: none;
    padding: 15px;
    position: relative;
    resize: none;
}
input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="file"],
input[type="search"],
select,
textarea {
    border: solid 1px $border-color;
    width: 100%;
}
textarea {
    height: 100px;
}

// Hidden
.hidden {
    display: none !important;
}

// Visible
.visible {
    display: block !important;
}

// Form Alerts
.form-alert {
    background-color: #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 15px rgba(#000000, 0.1);
    @include flex(
        $direction: column,
        $align: center,
        $justify: center
    );
    padding: 30px 20px;
    text-align: center;
    width: 100%;

    > i {
        font-size: 50px;
        margin: 0 0 20px;
    }

    h2 {
        font-size: 24px;
        font-weight: weight(regular);
        line-height: 1.3em;
        margin: 0;
    }

    span {
        display: block;
        font-size: 18px;
        font-weight: weight(light);
        line-height: 1.4em;
    }

    &.success {
        color: $success;
    }
    &.error {
        color: $error;
    }
}

// Box Form
.box-form {
    .field-group {
        position: relative;

        .field {
            @include transition;

            &.error {
                background-color: rgba($error, 0.05);
                border-color: rgba($error, 0.3);
            }
        }

        > span.error {
            color: $error;
            display: block;
            font-size: 12px;
            font-weight: weight(regular);
            line-height: 1.2em;
            position: absolute;
            margin: 4px 0 0;
            right: 0;
            top: 100%;
        }
    }
}

// Page Header
.page-header {
    background-color: #f1f1f1;
    @include imageBg(
        $size: cover
    );
    border-bottom: solid 4px $brand-color;
    margin: 0 0 70px;
    padding: 60px 0;
    text-align: center;

    .page-title {
        color: $text-color-dark;
        font-size: 60px;
        font-weight: weight(thin);

        &.-light {
            color: #ffffff;
        }
    }

    .page-uppertitle {
        color: $brand-color;
        font-size: 20px;
        font-weight: weight(extralight);
        line-height: 1.25em;
    }

    .page-subtitle {
        color: $brand-color;
        font-size: 20px;
        font-weight: weight(extralight);
        line-height: 1.25em;
        margin: 10px 0 0;
    }

    @include breakpoint-max(small) {
        margin: 0 0 40px;
        padding: 40px 0;
        .page-title {
            font-size: 36px;
        }
    }
}

body::-webkit-scrollbar {
    width: 8px;
}

body::-webkit-scrollbar-track {
    background-color: #F5F5F5;
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: $brand-color;
    border-radius: 10px;
}
body::-webkit-scrollbar-track-piece {
    border-radius: 10px;
}

.wow {
    visibility: hidden;
}