.page-contact {
    .page-content {
        .subtitle {
            color: $brand-color;
            font-size: 30px;
            margin: 0 0 20px;
            padding: 0 0 20px;
            position: relative;

            &::after {
                background-color: #d4b8be;
                bottom: 0;
                content: "";
                left: 0;
                position: absolute;
                @include size(30px, 1px)
            }
        }

        .box-form {
            position: relative;
            z-index: 2;

            > form {
                background-color: #fafafa;
                padding: 60px 90px 70px;
            }
    
            .field-group {
                & + .field-group {
                    margin: 20px 0 0;
                }
    
                > label {
                    color: $text-color-dark;
                    display: table;
                    font-size: 18px;
                    font-weight: weight(regular);
                    line-height: 1.2em;
                    margin: 0 0 10px;
                }
    
                &.buttons {
                    @include flex(
                        $justify: flex-end
                    );
                    margin: 30px 0 0;
    
                    .button {
                        background-color: #000000;
                        border-radius: 3px;
                        color: #ffffff;
                        cursor: pointer;
                        @include flex(
                            $justify: center,
                            $align: center
                        );
                        font-size: 15px;
                        font-weight: weight(regular);
                        line-height: 1.25em;
                        overflow: hidden;
                        padding: 16px 35px;
                        position: relative;
                        text-transform: uppercase;
                        @include transition;
                        z-index: 1;

                        &::before {
                            @include linearGradient(
                                $angle: to top
                            );
                            content: "";
                            left: 0;
                            opacity: 1;
                            position: absolute;
                            top: 0;
                            @include transition;
                            @include size(100%);
                            z-index: -1;
                        }

                        > i {
                            display: block;
                            font-size: 12px;
                            margin: 0 0 0 20px;
                        }
    
                        &:hover {
                            &::before {
                                opacity: 0;
                            }
                        }
                    }
                }
            }
        }
    
        .box-infos {
            .item-contacts {
                @include flex(
                    $direction: column,
                    $align: flex-start
                );
                padding: 0 0 130px;
                position: relative;
    
                &::after {
                    @include imageBg(
                        $image: "pattern.png",
                        $pos: 0 0,
                        $repeat: repeat
                    );
                    bottom: 0;
                    content: "";
                    left: 0;
                    position: absolute;
                    @include size(100%, 90px);
                }
    
                > a, p {
                    color: $text-color-light;
                    @include flex(
                        $align: center,
                        $wrap: nowrap
                    );
                    font-size: 24px;
                    font-weight: weight(light);
                    line-height: 1.2em;
                    position: relative;
                    word-break: break-all;
                    text-transform: uppercase;
    
                    & + a, + p {
                        margin: 20px 0 0;
                    }
    
                    &::before {
                        @include imageBg;
                        content: "";
                        display: block;
                        margin: 0 10px 0 0;
                        min-width: 40px;
                        @include size(40px);
                    }
    
                    > strong {
                        color: $text-color-dark;
                        font-weight: weight(regular);
                        margin: 0 10px 0 0;
                        @include transition;
                    }
        
                    &.phone {
                        &::before {
                            background-image: url(../images/icon-contacts-phone.png);
                        }
                    }
        
                    &.whatsapp {
                        &::before {
                            background-image: url(../images/icon-contacts-whatsapp.png);
                        }
                    }
        
                    &.email {
                        text-transform: lowercase;
                        &::before {
                            background-image: url(../images/icon-contacts-email.png);
                        }
                    }
                }
    
                > a {
                    @include transition;
    
                    &::before {
                        transform: rotate(0deg);
                        @include transition(
                            $duration: 0.5s
                        );
                    }
    
                    &:hover {
                        color: $brand-color;
    
                        &::before {
                            transform: rotate(360deg);
                        }
    
                        > strong {
                            color: $brand-color;
                        }
                    }
                }
            }
    
            .item-social-media {
                @include flex(
                    $align: center
                );
                margin: 40px 0 0;
    
                > p {
                    color: #b2b2b2;
                    font-size: 20px;
                    line-height: 1.2em;
                    text-transform: uppercase;
                }
    
                > ul {
                    @include flex(
                        $align: center
                    );
                    margin: 0 0 0 40px;
    
                    > li {
                        & + li {
                            margin: 0 0 0 10px;
                        }
    
                        > a {
                            @include imageBg;
                            display: block;
                            text-align: left;
                            text-indent: -9999px;
                            transform: rotate(0deg);
                            @include transition;
                            @include size(50px);
    
                            &.facebook {
                                background-image: url(../images/icons-social-media-contacts-facebook.png);
                            }
    
                            &.instagram {
                                background-image: url(../images/icons-social-media-contacts-instagram.png);
                            }
    
                            &:hover {
                                transform: rotate(360deg);
                            }
                        }
                    }
                }
            }
    
            .item-location {
                margin: 60px 0 0;
    
                .title {
                    color: rgba(#cccccc, 0.3);
                    @include flex(
                        $align: center,
                        $justify: flex-start
                    );
                    font-size: 50px;
                    font-weight: weight(regular);
                    margin: 0 0 30px;
                    position: relative;
                    text-transform: uppercase;
    
                    &::before {
                        @include imageBg(
                            $image: "icon-contact-location.png"
                        );
                        content: "";
                        display: block;
                        margin: 0 15px 0 0;
                        @include size(60px);
                    }
                    &::after {
                        color: $brand-color;
                        content: attr(data-text);
                        @include flex(
                            $align: center
                        );
                        font-size: 20px;
                        font-weight: weight(bold);
                        height: 100%;
                        left: 112px;
                        line-height: 1.2em;
                        position: absolute;
                        top: 0;
                        z-index: 2;
                    }
                }
    
                .address {
                    padding: 30px 100px 30px 10px;
                    position: relative;
                    z-index: 1;

                    &::before {
                        border: solid 1px #f6e4e8;
                        content: "";
                        position: absolute;
                        right: 0;
                        top: 0;
                        @include size(calc(100% + 100px), 100%);
                        z-index: -1;
                    }
    
                    > p {
                        color: #808080;
                        font-size: 20px;
                        font-weight: weight(regular);
                        line-height: 1.250em;
                    }
    
                    > a {
                        color: $brand-color;
                        display: table;
                        font-size: 16px;
                        font-weight: weight(regular);
                        margin: 15px 0 0;
                        text-decoration: underline;
                        text-transform: uppercase;
                        @include transition;
    
                        &:hover {
                            color: $brand-color-secondary;
                        }
                    }
                }
            }
        }

        .box-contact-partner {
            @include imageBg(
                $image: "bg-parceiro-contato.jpg"
            );
            border-radius: 5px;
            @include flex(
                $align: center,
                $justify: center
            );
            margin: 70px 0 0;
            padding: 45px 15px;

            > h3 {
                color: #ffffff;
                font-size: 30px;
                font-weight: weight(extralight);
                line-height: 1em;
                padding: 0 0 0 60px;
                position: relative;

                &::before {
                    @include imageBg(
                        $image: "icon-phone-partner.png"
                    );
                    content: "";
                    left: 0;
                    position: absolute;
                    top: calc(50% - 21px);
                    @include size(42px);
                }

                > span {
                    color: $brand-color;
                }
            }

            > a {
                color: #ffffff;
                display: block;
                font-size: 30px;
                font-weight: weight(extralight);
                margin: 0 0 0 25px;
                position: relative;
                @include transition;

                &:hover {
                    color: $brand-color;
                }
            }
        }

        .box-location {
            border: solid 1px #f6e4e8;
            @include flex(
                $align: center,
                $justify: center
            );
            margin: 60px 0 0;
            padding: 30px 15px;

            h3 {
                color: rgba(#cccccc, 0.3);
                @include flex(
                    $align: center,
                    $justify: flex-start
                );
                font-size: 50px;
                font-weight: weight(regular);
                padding: 0 90px 0 0;
                position: relative;
                text-transform: uppercase;

                &::before {
                    @include imageBg(
                        $image: "icon-contact-location.png"
                    );
                    content: "";
                    display: block;
                    margin: 0 15px 0 0;
                    @include size(60px);
                }
                &::after {
                    color: $brand-color;
                    content: attr(data-text);
                    @include flex(
                        $align: center
                    );
                    font-size: 20px;
                    font-weight: weight(bold);
                    height: 100%;
                    left: 112px;
                    line-height: 1.2em;
                    position: absolute;
                    top: 0;
                    z-index: 2;
                }
            }

            p {
                color: $text-color-light;
                font-size: 20px;
                font-weight: weight(regular);
                line-height: em(27, 20);
                margin: 0 0 0 40px;
                max-width: 350px;
                width: 100%;
            }

            a {
                background-color: #f5f5f5;
                color: $brand-color;
                border-radius: 5px;
                display: block;
                font-size: 16px;
                font-weight: weight(regular);
                margin: 0 0 0 50px;
                padding: 15px 35px;
                text-decoration: underline;
                text-transform: uppercase;
                @include transition;

                &:hover {
                    background-color: $brand-color;
                    color: #ffffff;
                }
            }
        }

        @include breakpoint-max(xlarge) {
            .box-infos {
                .item-location {
                    .title {
                        font-size: 44px;
                    }
                }
            }

            .box-location {
                p {
                    margin: 0 0 0 30px;
                }

                a {
                    margin: 0 0 0 30px;
                }
            }
        }

        @include breakpoint-max(large) {
            .subtitle {
                font-size: 24px;
            }

            .box-form {
                width: 100%;

                > form {
                    padding: 40px;
                }
            }

            .box-infos {
                margin: 50px 0 0;
                width: 100%;

                .item-contacts {
                    > a, p {
                        font-size: 18px;
                    }
                }

                .item-location {
                    .address {
                        padding: 25px;

                        &::before {
                            width: 100%;
                        }
                    }
                }
            }

            .box-contact-partner {
                align-items: center;
                flex-direction: column;
                text-align: center;

                > h3 {
                    font-size: 21px;
                    font-weight: weight(light);
                    padding: 0;

                    &::before {
                        display: none;
                    }

                    > span {
                        display: block;
                        margin: 5px 0 0;
                    }
                }

                > a {
                    margin: 20px 0 0;
                }
            }

            .box-location {
                align-items: center;
                flex-direction: column;
                text-align: center;

                h3 {
                    align-items: center;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    padding: 0;

                    &::before {
                        margin: 0 0 10px;
                    }

                    &::after {
                        display: block;
                        left: 0;
                        margin: -30px 0 0;
                        position: relative;
                        right: auto;
                    }
                }
                p {
                    font-size: 18px;
                    line-height: em(27, 18);
                    margin: 20px 0 0;
                }

                a {
                    margin: 20px 0 0;
                }
            }
        }

        @include breakpoint-max(small) {
            .box-form {
                > form {
                    padding: 20px;
                }

                .field-group {
                    &.buttons {
                        .button {
                            font-size: 15px;
                            padding: 20px;
                            width: 100%;
                        }
                    }
                }
            }

            .box-infos {

                .item-location {
                    .title {
                        font-size: 36px;
                        
                        &::before {
                            background-size: contain;
                            @include size(35px);
                        }

                        &::after {
                            font-size: 20px;
                            left: 72px;
                        }
                    }
                }
            }
        }
    }

    .box-iframe-map {
        margin: 70px 0 0;

        > iframe {
            display: block;
            @include size(100%, 460px);
        }
    }
}