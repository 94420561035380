.page-lookbook {
    & + .site-footer {
        margin: 120px 0 0;
    }

    .list-items {
        >.row {
            margin-bottom: -50px;
        }
    }

    .box-button {
        @include flex(
            $align: center,
            $justify: center
        );
        margin: 60px 0 0;

        a {
            border: solid 1px $brand-color;
            border-radius: 3px;
            color: $brand-color;
            @include flex(
                $align: center,
                $justify: center
            );
            font-size: 15px;
            font-weight: weight(regular);
            max-width: 310px;
            padding: 15px;
            text-transform: uppercase;
            width: 100%;
            @include transition;

            &::after {
                content: "\f054";
                display: block;
                font-family: 'Font Awesome 5 Free';
                font-size: 12px;
                font-weight: 900;
                margin: 0 0 0 15px;
            }

            > i {
                display: block;
                font-size: 12px;
                margin: 0 0 0 10px;
            }

            &:hover {
                background-color: $brand-color;
                color: #ffffff;
            }
        }
    }

    @include breakpoint-max(large) {
        & + .site-footer {
            margin: 60px 0 0;
        }

        .box-lookbook-item {
            width: 33.33%;
        }
    }

    @include breakpoint-max(medium) {
        .box-lookbook-item {
            width: 50%;
        }
    }

    @include breakpoint-max(small) {
        .box-lookbook-item {
            width: 100%;
        }
    }
}