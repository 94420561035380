.box-whatsapp {
    > a {
        background-color: #ffffff;
        border-radius: 3px;
        box-shadow: 0 3px 35px rgba(#000000, 0.1);
        color: $brand-color;
        display: block;
        margin: 0 auto;
        max-width: 210px;
        padding: 35px 15px 30px;
        position: relative;
        text-align: center;
        transform: translateY(0);
        @include transition;
        width: 100%;

        &::before {
            @include imageBg(
                $image: "icon-whatsapp.png",
                $pos: center 0
            );
            content: "";
            left: 0;
            position: absolute;
            top: -20px;
            @include size(100%, 52px)
        }

        > span {
            display: block;
        }

        &:hover {
            box-shadow: 0 3px 35px rgba(#000000, 0.2);
            transform: translateY(-5px);
        }
    }

    &.fixed {
        bottom: 15px;
        opacity: 1;
        position: fixed;
        right: 15px;
        visibility: visible;
        @include transition;
        width: 215px;
        z-index: 5;

        &.disable {
            opacity: 0;
            visibility: hidden;
        }

        @include breakpoint-max(large) {
            display: none !important;
        }
    }
}