// Font Size Default
$font-size-default: 17px;

// Font family Primary
$text-font: 'Kanit', sans-serif !default;

$font-primary-weight: (
  'thin': 100,
  'extralight': 200,
  'light': 300,
  'regular': 400,
  'medium': 500,
  'bold': 700,
  'extrabold': 800,
) !default;

// Brand Color
$brand-color: #c52127 !default;
$brand-color-secondary: #2d2626 !default;

// Text color
$text-color-light: #666666 !default;
$text-color-dark: #000000 !default;

// Error
$error: #d63031 !default;

// Success
$success: #27ae60 !default;

// Border Color
$border-color: #e5e5e5 !default;

// Container's maximum width
$max-width: 1200px !default;

// Breakpoints map
// @prop {String} keys - Keys are identifiers mapped to a given length
// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints-max: (
  'small':  (max-width: 575px),
  'medium': (max-width: 767px),
  'large':  (max-width: 991px),
  'xlarge': (max-width: ($max-width - 1px))
) !default;

// Breakpoints map
// @prop {String} keys - Keys are identifiers mapped to a given length
// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints-min: (
  'small':  (min-width: 576px),
  'medium': (min-width: 768px),
  'large':  (min-width: 992px),
  'xlarge': (min-width: $max-width)
) !default;